import React from "react"
import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import track from "@src/utils/track"

import GithubIntegration from "@src/icons/v2/github-mark.png"
import GithubBlog from "@src/icons/v2/github-blog.png"
import { ReactComponent as GoogleSheetsIcon } from "@src/icons/v2/google-sheets-icon.svg"
import { ReactComponent as AsanaIcon } from "@src/icons/v2/asana-icon.svg"
import { ReactComponent as TwitterIntergrations } from "@src/icons/v2/twitter-integrations.svg"
import { ReactComponent as YoutubeIntegration } from "@src/icons/v2/youtube-integrations.svg"
import { ReactComponent as StripeIntegration } from "@src/icons/v2/stripe-integrations.svg"
import { ReactComponent as SlackIntegration } from "@src/icons/v2/slack-integrations.svg"

import * as styles from "./variation.module.scss"

const GithubIntegrations = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/integrations")

  return (
    <Layout
      title="Automate and visualize workflows with GitHub integrations."
      description="To create integrations, retrieve data, and automate your workflows, build with the GitHub integration."
      url="https://canonic.dev/features/github-integrations"
      location={location}
    >
      <SplashWithIllustration
        isWhite
        screenshot={GithubIntegration}
        className={styles.SocialIntegrations}
        heading="Github Integration"
        title="Automate and visualize workflows with GitHub integrations."
        subtitle="To create integrations, retrieve data, and automate your workflows, build with the GitHub integration."
        buttons={[
          {
            title: "Clone & get started →",
            isPrimary: true,
            href: `${mainAppUrl}`,
            onClick: () => track("Start for free", location.pathname),
          },
        ]}
      />
      <Section blueGradient className={styles.SocialIntegrationsActions}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Features
        </Text.Heading>
        <Text.H2 white>Few of the Triggers and Actions</Text.H2>

        <FeatureGrid cards className={styles.SocialIntegrationsActionsCards}>
          {[
            {
              title: "View Commits",
              description: `The commits API allows you to retrieve information and commits.`,
            },

            {
              title: "Manage Release",
              description: `The releases API allows you to create, modify, and delete releases and release assets.`,
            },
            {
              title: "Manage Pull Requests",
              description: `The Pulls Requests API allows you to list, view, edit, create, and even merge pull requests.`,
            },
            {
              title: "Manage Collaborators",
              description: `The collaborators API allows you to add, invite, and remove collaborators from a repository.`,
            },
          ]}
        </FeatureGrid>
      </Section>

      <Section white lassName={styles.SocialIntegrationsSamples}>
        <div className={styles.SocialIntegrationsSamplesWrapper}>
          <div className={styles.SocialIntegrationsSamplesWrapperFirst}>
            <Text.Heading>Sample Project</Text.Heading>
            <Text.H2 lightBlueGradient>
              Clone the project built with GitHub integration or read the guide
              on how to build it
            </Text.H2>
            <FeatureGrid cards white>
              {[
                {
                  title: "A Github PR Dashboard with ReactJS and Material UI",
                  description:
                    "Track your PR's on Github in the form of a dashboard. A step-by-step guide to building your own custom PR Dashboard in just a matter of minutes.",
                  link: {
                    title: "Clone Project",
                    url: "https://app.canonic.dev/projects/61b0eedde08af1002f135b04/graph",
                  },
                },
              ]}
            </FeatureGrid>
          </div>
          <div className={styles.SocialIntegrationsSamplesWrapperSecond}>
            <div className={styles.blogCards}>
              <div>
                <figure className={styles.blogCardsFigure}>
                  <img src={GithubBlog} />
                </figure>
                <Text.H3>
                  A Github PR Dashboard with ReactJS and Material UI
                </Text.H3>

                <Text.P>
                  Track your PR's on Github in the form of a dashboard. A
                  step-by-step guide to building your own custom PR Dashboard in
                  just a matter of minutes.
                </Text.P>
                <a
                  href={
                    "https://dev.to/canonic/a-github-pr-dashboard-with-reactjs-and-material-ui-ae5"
                  }
                >
                  <button>Step by Step Guide </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section lightBlue className={styles.SocialIntegrationsApp}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>Pick an App to pair with</Text.H2>
        <FeatureGrid cards className={styles.SocialIntegrationsAppCards}>
          {[
            {
              icon: GoogleSheetsIcon,
              title: "Google Sheets",
              description: `Spreadsheets  |  Google `,
            },
            {
              icon: AsanaIcon,
              title: "Asana",
              description: `Project Management`,
            },
            {
              icon: TwitterIntergrations,
              title: "Twitter",
              description: `Social Media`,
            },
            {
              icon: YoutubeIntegration,
              title: "Youtube",
              description: `Entertainment`,
            },
            {
              icon: StripeIntegration,
              title: "Stripe",
              description: `Payment Gateway`,
            },
            {
              icon: SlackIntegration,
              title: "Slack",
              description: `Business Communication`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Section black className={styles.SocialIntegrationsBenefits}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>
          Here’s why you should integrate using Canonic
        </Text.H2>
        <FeatureGrid
          vertical
          fullWidth
          className={styles.SocialIntegrationsBenefitsCards}
        >
          {[
            {
              //   icon: PlaceholderIcon,
              title: "Plenty of Inegrations",
              description: `A good deal of full featured set of integrations.`,
            },
            {
              //   icon: PlaceholderIcon,
              title: "Chanining Multiple Integration",
              description: `Multiple integrations can be chained to one another.`,
            },
            {
              //   icon: PlaceholderIcon,
              title: "Test your Data",
              description: `Test the data you are receiving on the GraphQL Playground.`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Callout location={location} title="githubIntegrations" />
      <CommunityCallout />
    </Layout>
  )
}

export default GithubIntegrations
